$theme:(
    light:(
        textColor: #000,
        bg: white,
        body: #F0F2F5,
        box: white,
        hbg: #F0F2F5,
        logo: #868065,
        bgSoft: #f6f3f3,
        textColorsoft: #555,
        border: lightgray,
    ),
    dark:(
        textColor: whitesmoke,
        bg: #222,
        body: #222,
        hbg: #555353,
        box: #555353,
        logo: white,
        bgSoft: #a09f9f,
        textColorsoft: lightgray,
        border: #444,
    ),
);
@mixin themify($themes) {
    @each $theme, $map in $themes {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), "#{$key}");
          $theme-map: map-merge(
            $theme-map,
            (
              $key: $value,
            )
          ) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }
  
  @mixin mobile {
    @media (max-width: 480px) {
      @content;
    }
  }
  @mixin tablet {
    @media (max-width: 960px) {
      @content;
    }
  }