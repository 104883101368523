@import "../../style.scss";

.home{
    @include themify($theme) {
    background-color: themed("body");
    color: themed("textColor");
    padding: 10px;
    height: 100%;

    .box{
        background-color: white;
        padding: 20px;
        border-radius: 05px;
    }

    .table{
        background-color: white;
    }
 }
}