@import "../../style.scss";

.navbars{
    @include themify($theme) {
    display: flex;
    height: 50px;
    background-color: themed("bg");
    border-bottom: 0.5px solid rgb(231, 228, 228);
    align-items: center;
    font-size: 14px;
    color: themed("textColor");

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo{
            font-size: 20px;
            font-weight: bold;
            color: themed("logo");
        }
    }

    .wrapper{
         padding: 20px;
         display: flex;
         align-items: center;
         width: 100%;
         justify-content: space-between;

        .title{
            display: flex;
            align-items: center;
            padding: 3px;
            font-weight: 400;
            font-size: 15px;
        }

         .items{
            display: flex;
            align-items: center;

            .item{
                display: flex;
                align-items: center;
                margin-right: 20px ;
                position: relative;
                .icon{
                    font-size: 20px;
                }

                .avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .counter{
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                }
                name{
                    font-size: 15px;
                    padding: 10px;
                    font-weight: 500;
                }
            }
         }
    }
}
}