@import "../../style.scss";

.timetable{
    @include themify($theme) {
    background-color: themed("body");
    color: themed("textColor");
    padding: 20px;
    height: 100%;

    .btn-back{
        background-color: #2196F3;
        color: white;
    }
    .top{
        display: flex;
        justify-content: space-between;
        background-color: themed("box");
        color: themed("textColor");
        padding: 15px;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 5px;
    }
    .bottom{
        background-color: themed("box");
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 20px;
        .btn-view{
            background-color: aqua;
        }
        
    }
    .box{
        background-color: white;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 5px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
             box-shadow:2px 4px 10px 1px rgba(201, 201, 201, 0.20);

             .icon{
                font-size: 14px;
                
             }
    }
    .btn-add{
        background-color: #2196F3;
        color: white;
        text-decoration: none;
        margin: 0 10px ;
        padding: 3px;
        padding-right: 10px;
        border-radius: 4px;
        font-size: 12px;
    }
    
 }}