@import "../../style.scss";

.sidebar{
    @include themify($theme) {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    height: 90vh;
    background-color: themed("bg");

    .center{
        padding-left: 10px;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;

            .title{
                font-size: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }
            li{
                display: flex;
                align-items: center;
                padding: 8px;
                cursor: pointer;

                &:hover{
                    background-color: themed("hbg");
                }

                .icon{
                    font-size: 18px;
                    color: themed("textColor");
                }
                
                span{
                    font-size: 13px;
                    font-weight: 600;
                    color: themed("textColor");
                    margin-left: 10px;
                }
            }
        }
    }
}
}