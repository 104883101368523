.login {
  height: 100vh;
  background-color: rgb(193, 190, 255);
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    width: 50%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    min-height: 600px;
    overflow: hidden;

    .left {
      flex: 1;
      background: linear-gradient(rgba(39, 11, 96, 0.5), rgba(39, 11, 96, 0.5)),
        url("https://images.pexels.com/photos/3228727/pexels-photo-3228727.jpeg?auto=compress&cs=tinysrgb&w=1600")
          center;
      background-size: cover;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      color: white;

      h1 {
        font-size: 100px;
        line-height: 100px;
      }
      
      span {
        font-size: 14px;
      }
      
      button {
        width: 50%;
        padding: 10px;
        border: none;
        background-color: white;
        color: rebeccapurple;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .right {
      flex: 1;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: center;

      h1 {
      color: #555;
      }
      
      form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        
        input {
        border: none;
        border-bottom: 1px solid lightgray;
        padding: 20px 10px;
        }
        
        button {
          width: 50%;
          padding: 10px;
          border: none;
          background-color: #938eef;
          color: white;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
