@import "../../style.scss";

.employee{
    @include themify($theme) {
    background-color: themed("body");
    height: 100%;
    padding: 10px;

    .top{
        display: flex;
        justify-content: space-between;
        background-color: themed("box");
        color: themed("textColor");
        padding: 15px;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 5px;
    }
    .bottom{
        background-color: themed("box");
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 20px;
        td, th{
            color: themed("textColorsoft");
          
            .icon{
                font-size: 18px;
            }
        }
        
        .btn-color{
            background-color: #1265A8;
            color: #ffffff;
        }
        .btn-back{
            background-color: #3B5166;
            color: white;
        }
    }
 }}